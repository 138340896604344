import React from 'react';
import { Carousel, CarouselItem } from 'reactstrap';

interface IActiveProductsCarouselProps {
    activeIndex?: number;
    products: React.ReactNode[];
    next: () => void;
    prev: () => void;
}

const ActiveProductsCarousel: React.FC<IActiveProductsCarouselProps> = ({ activeIndex = 0, products, next, prev }) => {
    return (
        <Carousel activeIndex={activeIndex} next={next} previous={prev} interval={false} className='msc-ss-carousel'>
            <button className='msc-flipper  msc-ss-carousel__flipper left' onClick={prev} disabled={activeIndex === 0}>
                <i className='msi-chevron-left msc-no-rtl-flip-required' aria-hidden='true'></i>
            </button>
            {products.map(product => (
                <CarouselItem key={''}>{product}</CarouselItem>
            ))}
            <button className='msc-flipper  msc-ss-carousel__flipper right' onClick={next} disabled={activeIndex === products.length - 1}>
                <i className='msi-chevron-right msc-no-rtl-flip-required' aria-hidden='true'></i>
            </button>
        </Carousel>
    );
};

export { ActiveProductsCarousel };
export default ActiveProductsCarousel;
